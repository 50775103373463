<template>
  <layout>
    <v-container fluid style="height: 100%;">
      <h1>Statistics</h1>
      <div style="width: 100%; height: 500px">
        <Chart v-if="loaded" :chartdata="datacollection" :options="options"></Chart>
      </div>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';
import Chart from '../components/Chart';

export default {
  metaInfo: {
    title: 'Statistics'
  },
  data () {
    return {
      loaded: false,
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Stats'
        },
        scales: {
          xAxes: [{
            type: 'time',
            display: true,
            time: {
              unit: 'day'
            },
            scaleLabel: {
              display: true,
              labelString: 'Date'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Closing price ($)'
            }
          }]
        },
        tooltips: {
          intersect: true,
          mode: 'index',
          callbacks: {
            label: function (tooltipItem, myData) {
              let label = myData.datasets[tooltipItem.datasetIndex].label || '';
              if (label) {
                label += ': ';
              }
              if (tooltipItem.datasetIndex === 0 || tooltipItem.datasetIndex === 1) {
                label += parseFloat(tooltipItem.value).toFixed(0);
              } else {
                label += parseFloat(tooltipItem.value).toFixed(2) + ' hours';
              }
              return label;
            }
          }
        }
      }
    };
  },
  components: {
    Chart,
    Layout
  },
  computed: {},
  methods: {
    fillData () {
      let moviePlaysPerDay = {};
      let tvPlaysPerDay = {};
      let movieTimePerDay = {};
      let tvTimePerDay = {};
      firebase.firestore().collection('plays').orderBy('timestamp', 'asc').get().then(value => {
        value.forEach(snapshot => {
          let play = snapshot.data();
          if (play.watchingTime === 0) {
            if (firebase.auth().currentUser.uid === 'YXV5Q5r7I0eKwHhfK8EcCp84V8I3') {
              snapshot.ref.delete();
            }
          } else {
            let date = moment.unix(play.timestamp.seconds).startOf('day');
            let key = date.unix();
            if (play.type === 'tv') {
              if (tvPlaysPerDay.hasOwnProperty(key)) {
                tvPlaysPerDay[key].y++;
              } else {
                tvPlaysPerDay[key] = {
                  x: date.toDate(),
                  y: 1
                };
              }
              if (tvTimePerDay.hasOwnProperty(key)) {
                tvTimePerDay[key].y += play.watchingTime;
              } else {
                tvTimePerDay[key] = {
                  x: date.toDate(),
                  y: play.watchingTime
                };
              }
            }
            if (play.type === 'movie') {
              if (moviePlaysPerDay.hasOwnProperty(key)) {
                moviePlaysPerDay[key].y++;
              } else {
                moviePlaysPerDay[key] = {
                  x: date.toDate(),
                  y: 1
                };
              }
              if (movieTimePerDay.hasOwnProperty(key) && movieTimePerDay[key].y) {
                movieTimePerDay[key].y += play.watchingTime;
              } else {
                movieTimePerDay[key] = {
                  x: date.toDate(),
                  y: play.watchingTime
                };
              }
            }
          }
        });

        let keys = [];
        for (let key in tvPlaysPerDay) {
          if (tvPlaysPerDay.hasOwnProperty(key) && !keys.includes(key)) {
            keys.push(key);
          }
        }
        for (let key in moviePlaysPerDay) {
          if (moviePlaysPerDay.hasOwnProperty(key) && !keys.includes(key)) {
            keys.push(key);
          }
        }
        for (let key in movieTimePerDay) {
          if (movieTimePerDay.hasOwnProperty(key) && !keys.includes(key)) {
            keys.push(key);
          }
        }
        for (let key in tvTimePerDay) {
          if (tvTimePerDay.hasOwnProperty(key) && !keys.includes(key)) {
            keys.push(key);
          }
        }

        let tvPlaysPerDayArray = [];
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (tvPlaysPerDay.hasOwnProperty(key) && tvPlaysPerDay[key]) {
            tvPlaysPerDayArray.push(tvPlaysPerDay[key]);
          } else {
            let date = moment.unix(key);
            tvPlaysPerDayArray.push({
              x: date.toDate(),
              y: 0
            });
          }
        }
        let moviePlaysPerDayArray = [];
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (moviePlaysPerDay.hasOwnProperty(key) && moviePlaysPerDay[key]) {
            moviePlaysPerDayArray.push(moviePlaysPerDay[key]);
          } else {
            let date = moment.unix(key);
            moviePlaysPerDayArray.push({
              x: date.toDate(),
              y: 0
            });
          }
        }
        let movieTimePerDayArray = [];
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (movieTimePerDay.hasOwnProperty(key) && movieTimePerDay[key]) {
            movieTimePerDay[key].y = movieTimePerDay[key].y / 60 / 60;
            movieTimePerDayArray.push(movieTimePerDay[key]);
          } else {
            let date = moment.unix(key);
            movieTimePerDayArray.push({
              x: date.toDate(),
              y: 0
            });
          }
        }
        let tvTimePerDayArray = [];
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (tvTimePerDay.hasOwnProperty(key) && tvTimePerDay[key]) {
            tvTimePerDay[key].y = tvTimePerDay[key].y / 60 / 60;
            tvTimePerDayArray.push(tvTimePerDay[key]);
          } else {
            let date = moment.unix(key);
            tvTimePerDayArray.push({
              x: date.toDate(),
              y: 0
            });
          }
        }

        tvPlaysPerDayArray.sort(function (a, b) {
          return a.x - b.x;
        });
        moviePlaysPerDayArray.sort(function (a, b) {
          return a.x - b.x;
        });
        movieTimePerDayArray.sort(function (a, b) {
          return a.x - b.x;
        });
        tvTimePerDayArray.sort(function (a, b) {
          return a.x - b.x;
        });

        this.datacollection = {
          datasets: [
            {
              label: 'Movie Plays',
              backgroundColor: '#f87979',
              borderColor: '#f87979',
              stack: 'plays',
              fill: false,
              data: moviePlaysPerDayArray
            }, {
              label: 'TV Plays',
              backgroundColor: '#4cc1f8',
              borderColor: '#4cc1f8',
              stack: 'plays',
              fill: false,
              data: tvPlaysPerDayArray
            }, {
              label: 'Movie Time',
              backgroundColor: '#f8b266',
              borderColor: '#f8b266',
              stack: 'time',
              fill: false,
              data: movieTimePerDayArray
            }, {
              label: 'TV Time',
              backgroundColor: '#4846f8',
              borderColor: '#4846f8',
              stack: 'time',
              fill: false,
              data: tvTimePerDayArray
            }
          ]
        };
        this.loaded = true;
      });
    }
  },
  created: function () {
    this.fillData();
  }
};
</script>
